@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;700&display=swap");

@layer base {
  html {
    font-family: Noto Sans;
  }
  :not(input) {
    user-select: none;
  }
}

@layer components {
  .h-screen {
    height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100);
  }

  /* Typography */

  .heading-1 {
    @apply text-[2.75rem] font-bold tracking-[-0.03125rem] leading-[3rem] space-x-[2rem];
  }

  .heading-2 {
    @apply text-[2rem] font-bold tracking-[-0.03125rem] leading-[2rem] space-x-[1rem];
  }

  .heading-3 {
    @apply text-[1.5rem] font-bold tracking-[-0.03125rem] leading-[2rem] space-x-[1rem];
  }

  .heading-4 {
    @apply text-[1.25rem] font-semibold tracking-[-0.03125rem] leading-[1.5rem] space-x-[1rem];
  }

  .heading-5 {
    @apply text-[1rem] font-semibold tracking-[-0.015625rem] leading-[1.5rem] space-x-[0.5rem];
  }

  .heading-6 {
    @apply text-[0.875rem] tracking-[-0.015625rem] leading-[1.25rem] space-x-0;
  }

  .body-large {
    @apply text-base font-medium tracking-[-0.015625rem] leading-[1.5rem] space-x-[0.5rem];
  }

  .body-small {
    @apply text-sm font-medium tracking-[-0.015625rem] leading-[1.25rem] space-x-0;
  }

  .body-micro {
    @apply text-xs font-semibold tracking-[-0.015625rem] leading-[1rem] space-x-0;
  }

  .body-nano {
    @apply text-[0.75rem] font-semibold tracking-[-0.015625rem] leading-[1rem] space-x-0;
  }

  .btn-text-large {
    @apply text-[1rem] font-semibold tracking-[-0.015625rem] leading-[1.5rem] space-x-[0.5rem];
  }

  .btn-text-small {
    @apply text-[0.875rem] font-semibold tracking-[-0.015625rem] leading-[1.25rem] space-x-0;
  }

  .link-text-large {
    @apply text-[1rem] font-semibold tracking-[-0.015625rem] leading-[1.5rem] space-x-[0.5rem] underline;
  }

  .link-text-small {
    @apply text-[0.875rem] font-semibold tracking-[-0.015625rem] leading-[1.25rem] space-x-0 underline;
  }

  /* Button */
  .btn {
    @apply flex flex-row justify-center items-center rounded-full body-small font-semibold;
  }

  .btn-xlarge {
    @apply p-[1rem_1.5rem] gap-[0.5rem];
  }

  .btn-large {
    @apply p-[0.75rem_1.5rem] gap-[0.5rem];
  }

  .btn-medium {
    @apply p-[0.5rem_1.5rem] gap-[0.5rem];
  }

  .btn-small {
    @apply p-[0.375rem_1.5rem] gap-[0.5rem];
  }

  /* Icon Button */
  .icon-btn {
    @apply flex flex-row justify-center items-center rounded-full;
  }

  .icon-btn-xlarge {
    @apply p-[1rem];
  }

  .icon-btn-xlarge > img {
    @apply w-[1.5rem] h-[1.5rem];
  }

  .icon-btn-xlarge > img {
    @apply w-[1.5rem] h-[1.5rem];
  }

  .icon-btn-large {
    @apply p-[.75rem];
  }

  .icon-btn-large > img {
    @apply w-[1.5rem] h-[1.5rem];
  }

  .icon-btn-medium {
    @apply p-[.625rem];
  }

  .icon-btn-medium > img {
    @apply w-[1.25rem] h-[1.25rem];
  }

  .icon-btn-small {
    @apply p-[.25rem];
  }

  .icon-btn-small > img {
    @apply w-[1rem] h-[1rem];
  }

  .btn-normal {
    @apply bg-primary-70 text-neutral-0 active:bg-primary-90 disabled:text-neutral-40 disabled:bg-neutral-10;
  }

  .btn-danger {
    @apply bg-danger-10 text-neutral-0 active:bg-danger-30 disabled:text-neutral-40 disabled:bg-neutral-10;
  }

  .btn-secondary {
    @apply bg-white text-primary-70 border-[1px] border-neutral-20;
  }

  .btn-tertiary {
    @apply bg-transparent text-primary-70;
  }

  .btn-danger.btn-secondary {
    @apply bg-white text-danger-30 border-[1px] border-neutral-20;
  }

  .btn-danger.btn-tertiary {
    @apply bg-transparent text-danger-30;
  }

  /* Text Input */

  .input {
  }

  .input-label {
    @apply body-small font-bold;
  }

  .input-label-active {
    @apply text-primary-70;
  }

  .input-label-error {
    @apply text-danger-10;
  }

  .input-label-required {
    @apply after:content-["_*"] after:text-danger-10;
  }

  .input-label-optional {
    /* @apply after:content-["_(Optional)"] after:text-neutral-50; */
  }

  .input-container {
    @apply flex flex-row items-center bg-neutral-0 rounded-[0.25rem] gap-[0.5rem] border-[1px] border-transparent;
  }

  .input-container-medium {
    @apply p-[0.75rem_1rem];
  }

  .input-container-small {
    @apply p-[0.5rem_1rem];
  }

  .input-container-active {
    @apply bg-primary-0 border-primary-70;
  }

  .input-container-success {
    @apply bg-success-0 border-success-40;
  }

  .input-container-error {
    @apply bg-danger-0 border-[1px] border-danger-10;
  }

  .input-area {
    @apply outline-none w-full bg-transparent leading-[1.5rem] tracking-[-0.015625rem] text-neutral-100 placeholder:text-neutral-50;
  }
}
